/**
 * @ABS === ABSOLUTE
*/

import * as routes from './routes';

export const ABS_ACCOUNT_LOGIN = `/${routes.ACCOUNT}/${routes.LOGIN}`;
export const ABS_FORGOT_PASSWORD = `/${routes.ACCOUNT}/${routes.FORGOT_PASSWORD}`;
export const ABS_RESET_PASSWORD = `/${routes.ACCOUNT}/${routes.RESET_PASSWORD}`;


export const ABS_PROFILE = `/${routes.PROFILE}`;
export const ABS_PROFILE_EDIT = `/${routes.PROFILE}/${routes.EDIT}`;
export const ABS_PROFILE_CHANGE_PASS = `/${routes.PROFILE}/${routes.CHANGE_PASSWORD}`;

export const ABS_DASHBOARD = `/${routes.DASHBOARD}`;
export const ABS_CATEGORIES = `/${routes.CATEGORIES}`;

export const ABS_ATHLETES = `/${routes.ATHLETES}`;
export const ABS_ATHLETE_DETAILS = `/${routes.ATHLETES}/${routes.DETAILS}`;

export const ABS_COACHES = `/${routes.COACHES}`;
export const ABS_COACH_DETAILS = `/${routes.COACHES}/${routes.DETAILS}`;

export const ABS_WORKOUTS = `/${routes.WORKOUTS}`;
export const ABS_WORKOUTS_ADD = `/${routes.WORKOUTS}/${routes.ADD}`;
export const ABS_WORKOUTS_EDIT = `/${routes.WORKOUTS}/${routes.EDIT}`;
export const ABS_WORKOUT_DETAILS = `/${routes.WORKOUTS}/${routes.DETAILS}`;

export const ABS_NOTIFICATION = `/${routes.NOTIFICATION}`;
export const ABS_NOTIFICATION_ADD = `/${routes.NOTIFICATION}/${routes.ADD}`;
export const ABS_NOTIFICATION_EDIT = `/${routes.NOTIFICATION}/${routes.EDIT}`;
export const ABS_NOTIFICATION_DETAILS = `/${routes.NOTIFICATION}/${routes.DETAILS}`;

export const ABS_EARNINGS = `/${routes.EARNINGS}`;

export const ABS_DRILLS = `/${routes.DRILLS}`;
export const ABS_DRILLS_ADD = `/${routes.DRILLS}/${routes.ADD}`;
export const ABS_DRILLS_EDIT = `/${routes.DRILLS}/${routes.EDIT}`;
export const ABS_DRILLS_DETAILS = `/${routes.DRILLS}/${routes.DETAILS}`;

export const ABS_CMS = `/${routes.CMS}`;
export const ABS_TERM_CONDITIONS = `/${routes.TERM_CONDITIONS}`;
export const ABS_PRIVACY_POLICY = `/${routes.PRIVACY_POLICY}`;
export const ABS_ABOUT_US = `/${routes.ABOUT_US}`;
export const ABS_CONTACT_US = `/${routes.CONTACT_US}`;
export const ABS_FAQ = `/${routes.FAQ}`;
