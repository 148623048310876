import { Injectable } from '@angular/core';
import { NOTIFICATION_ACTION_API, NOTIFICATION_EDIT_API, NOTIFICATION_ADD_EDIT_API, NOTIFICATION_DETAILS_API, NOTIFICATION_API } from 'src/app/constants/api-end-point';
import { HttpService } from 'src/app/services/http/http.service';

@Injectable()
export class NotificationService {

  constructor(
    private _http: HttpService
  ) { }

  getNotificationList(query, showLoader = false) {
    return this._http.get(NOTIFICATION_API, query, showLoader);
  }

  addNotification(body) {
    return this._http.post(NOTIFICATION_ADD_EDIT_API, body);
  }

  updateNotification(body) {
    return this._http.post(NOTIFICATION_EDIT_API, body);
  }

  rowActions(body: any) {
    return this._http.delete(NOTIFICATION_ACTION_API, body);
  }

  getNotificationDetail(query, showLoader = false) {
    return this._http.get(NOTIFICATION_DETAILS_API, query, showLoader);
  }
}
